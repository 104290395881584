import React from "react"
import componentStyles from "./SquareBlockList.module.scss"

interface SquareBlockList {
  itemList: string[],
  backgroundColor?: string,
  liStyles?: React.CSSProperties,
}

const SquareBlockList: React.FC<SquareBlockList> = ({ itemList, backgroundColor, liStyles }) => {
  const listItems = itemList.map((item) =>
    <li className={componentStyles.numberedListItem} style={liStyles} key={item}>
      <span className={componentStyles.numberedListItemText} dangerouslySetInnerHTML={{ __html: item }}>
      </span>
    </li>
  );

  const style = backgroundColor ? { background: backgroundColor } : {background: '#F9F9F9'};
  const withoutBgClass = backgroundColor == 'none' ? componentStyles.withoutBg : '';

  return (
    <ol className={componentStyles.numberedList + ' ' + withoutBgClass} style={style}>
      {listItems}
    </ol>
  );
};

export default SquareBlockList
