import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./convidi.module.scss"
import ConvidiMacbook from "../../assets/images/convidi_macbook.png"
import Phones from "../../assets/images/phones.png"
import ConvidiMacbook2 from "../../assets/images/convidi_macbook2.png"
import LearnMoreSection from "../../components/LearnMoreSection/LearnMoreSection"
import SquareBlockList from "../../components/SquareBlockList/SquareBlockList"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

function createMarkup(safetyString: string) {
  return {__html: safetyString};
}

const LoyaltyManager: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()

  //for adaptive images size
  const breakpoints = useBreakpoint();

  const bannerClasses = classNames(componentStyles.banner)

  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    componentStyles.container,
    componentStyles.LtrDirection,
    "container",
    "grid-2"
  )

  const sectionClasses = classNames(
    componentStyles.section,
    componentStyles.container,
    componentStyles.LtrDirection,
    "container"
  )

  const squareBlockList = [
    intl.formatMessage({
      id: "projects.convidi.challenge.paragraph-3-list.item-1",
    }),
    intl.formatMessage({
      id: "projects.convidi.challenge.paragraph-3-list.item-2",
    }),
    intl.formatMessage({
      id: "projects.convidi.challenge.paragraph-3-list.item-3",
    }),
    intl.formatMessage({
      id: "projects.convidi.challenge.paragraph-3-list.item-4",
    })
  ]

  const liStylesForSquareBlockList = {
    maxWidth: '240px',
    marginTop: 0,
    padding: "12px 0",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 20
  }

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.convidi.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.convidi.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                  id: "projects.convidi.banner.title",
                })}
            </h1>
        </div>
      </section>
      <section className={sectionClasses}>
        <div className={componentStyles.clientCompany}>
          <h2 className={componentStyles.title2}>
            {intl.formatMessage({
                  id: "projects.convidi.client-company.title",
              })}
          </h2>
          <div className={componentStyles.text}>
            {intl.formatMessage({
                  id: "projects.convidi.client-company.text",
              })}
          </div>
        </div>
      </section>
      <section className={sectionClasses}>
        <h2 className={componentStyles.title2}>
          {intl.formatMessage({
              id: "projects.convidi.challenge.title",
            })}
        </h2>
        <div className={componentStyles.challengeBlock}>
            <div className={componentStyles.item1}>
              <div className={componentStyles.text}>
                {intl.formatMessage({
                  id: "projects.convidi.challenge.paragraph-1",
                  })}
              </div>
              <div className={componentStyles.text}>
                {intl.formatMessage({
                  id: "projects.convidi.challenge.paragraph-2",
                  })}
                <a href="https://www.morganstanley.com/ideas/global-ecommerce-growth-forecast-2022" target="_blank">
                  {intl.formatMessage({
                    id: "projects.convidi.challenge.paragraph-2-link",
                    })}
                </a>
              </div>
              <div className={componentStyles.text + ' ' + componentStyles.bold}>
                {intl.formatMessage({
                  id: "projects.convidi.challenge.paragraph-3",
                  })}
              </div>
            </div>

            <img
              className={componentStyles.item2}
              src={ConvidiMacbook}
              alt="Illustration with Convidi Macbook"
              width={breakpoints.mediaBefore800 ? '267' : '461'}
              height={breakpoints.mediaBefore800 ? '178' : '327'}
            />

          <div className={componentStyles.item3}>
            <SquareBlockList itemList={squareBlockList} liStyles={liStylesForSquareBlockList} backgroundColor={'none'}/>
          </div>
        </div>
      </section>
      <section className={sectionClasses}>
        <div >
            <div>
              <h2 className={componentStyles.title2}>
                {intl.formatMessage({
                  id: "projects.convidi.solution-description.title",
                  })}
              </h2>
              <div className={ componentStyles.solutionDescriptionBlock}>
                <div  className={componentStyles.item1}>
                  <div className={componentStyles.text}>
                    {intl.formatMessage({
                      id: "projects.convidi.solution-description.paragraph-1.text-before-link",
                    })}
                    <a href="https://convidi.com/" target="_blank">
                      {intl.formatMessage({
                        id: "projects.convidi.solution-description.paragraph-1.link",
                      })}
                    </a>
                    {intl.formatMessage({
                      id: "projects.convidi.solution-description.paragraph-1.text-after-link",
                    })}
                  </div>
                  <div className={componentStyles.text}>
                    {intl.formatMessage({
                      id: "projects.convidi.solution-description.paragraph-2",
                      })}
                  </div>
                </div>
                <img
                  className={componentStyles.item2}
                  src={ConvidiMacbook2}
                  alt="Illustration with Convidi Macbook2"
                  width={breakpoints.mediaBefore800 ? '238' : '479'}
                  height={breakpoints.mediaBefore800 ? '119' : '240'}
                />
                <div className={componentStyles.bold + ' ' + componentStyles.item3}>
                  {intl.formatMessage({
                    id: "projects.convidi.solution-description.paragraph-3-title",
                    })}
                </div>
              </div>
            </div>
        </div>
        <div className={componentStyles.benefitsBlock}>
          <div className={componentStyles.benefit}>
            <img
              src="/icons/acquisition.svg"
              alt="acquisition image"
              width={breakpoints.mediaBefore800 ? '20' : '30'}
              height={breakpoints.mediaBefore800 ? '20' : '30'}
            />
            <div className={componentStyles.bold}>
              {intl.formatMessage({
                  id: "projects.convidi.solution-description.paragraph-3-list.item-1",
                })}
            </div>
            <ul className={componentStyles.cubes}>
              <li>
                  {intl.formatMessage({
                    id: "projects.convidi.solution-description.paragraph-3-list.item-1-list.item-1",
                    })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.solution-description.paragraph-3-list.item-1-list.item-2",
                  })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.solution-description.paragraph-3-list.item-1-list.item-3",
                  })}
              </li>
            </ul>
          </div>
          <div className={componentStyles.benefit}>
            <img
              src="/icons/customer-service.svg"
              alt="customer service image"
              width={breakpoints.mediaBefore800 ? '20' : '30'}
              height={breakpoints.mediaBefore800 ? '20' : '30'}
            />
            <div className={componentStyles.bold}>
              {intl.formatMessage({
                  id: "projects.convidi.solution-description.paragraph-3-list.item-2",
                })}
            </div>
            <ul className={componentStyles.cubes}>
              <li>
                {intl.formatMessage({
                    id: "projects.convidi.solution-description.paragraph-3-list.item-2-list.item-1",
                  })}
              </li>
              <li>
                  {intl.formatMessage({
                      id: "projects.convidi.solution-description.paragraph-3-list.item-2-list.item-2",
                    })}
              </li>
              <li>
                {intl.formatMessage({
                    id: "projects.convidi.solution-description.paragraph-3-list.item-2-list.item-3",
                  })}
              </li>
            </ul>
          </div>
          <div className={componentStyles.benefit}>
            <img
              src="/icons/customer-loyalty.svg"
              alt="customer loyalty image"
              width={breakpoints.mediaBefore800 ? '20' : '30'}
              height={breakpoints.mediaBefore800 ? '20' : '30'}
            />
            <div className={componentStyles.bold}>
              {intl.formatMessage({
                  id: "projects.convidi.solution-description.paragraph-3-list.item-3",
                })}
            </div>
            <ul className={componentStyles.cubes}>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.solution-description.paragraph-3-list.item-3-list.item-1",
                  })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.solution-description.paragraph-3-list.item-3-list.item-2",
                  })}
              </li>
            </ul>
          </div>
          <div className={componentStyles.benefit}>
            <img
              src="/icons/economy.svg"
              alt="economy image"
              width={breakpoints.mediaBefore800 ? '20' : '30'}
              height={breakpoints.mediaBefore800 ? '20' : '30'}
            />
            <div className={componentStyles.bold}>
                {intl.formatMessage({
                  id: "projects.convidi.solution-description.paragraph-3-list.item-4",
                  })}
            </div>
            <ul className={componentStyles.cubes}>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.solution-description.paragraph-3-list.item-4-list.item-1",
                  })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.solution-description.paragraph-3-list.item-4-list.item-2",
                  })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.solution-description.paragraph-3-list.item-4-list.item-3",
                  })}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className={sectionClasses}>
        <h2 className={componentStyles.title2}>
          {intl.formatMessage({
            id: "projects.convidi.technology-background.title",
          })}
        </h2>
        <div className={componentStyles.technologyBackgroundBlock}>
          <div className={componentStyles.item1}>
            {intl.formatMessage({
              id: "projects.convidi.technology-background.paragraph-1",
            })}
          </div>
          <div className={componentStyles.item2}>
            <h3 className={componentStyles.title3}>
              {intl.formatMessage({
                id: "projects.convidi.technology-background.paragraph-2",
              })}
            </h3>
            <ul className={componentStyles.cubes}>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.technology-background.paragraph-2-list.item-1",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.technology-background.paragraph-2-list.item-2",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.technology-background.paragraph-2-list.item-3",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.technology-background.paragraph-2-list.item-4",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "projects.convidi.technology-background.paragraph-2-list.item-5",
                })}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className={sectionClasses}>
        <h2 className={componentStyles.title2}>
          {intl.formatMessage({
            id: "projects.convidi.results.title",
          })}
        </h2>
        <div className={componentStyles.resultsBlocks}>
          <div>
            <div className={componentStyles.item1}>
              <img
                src="/icons/tick.svg"
                alt="tick image"
                width={breakpoints.mediaBefore800 ? '36' : '30'}
                height={breakpoints.mediaBefore800 ? '26' : '30'}
              />
              <div>
                {intl.formatMessage({
                  id: "projects.convidi.results.paragraph-1",
                })}
              </div>
            </div>
            <div className={componentStyles.item1}>
              <img
                src="/icons/tick.svg"
                alt="tick image"
                width={breakpoints.mediaBefore800 ? '36' : '30'}
                height={breakpoints.mediaBefore800 ? '26' : '30'}
              />
              <div>
                {intl.formatMessage({
                  id: "projects.convidi.results.paragraph-2",
                })}
              </div>
            </div>
            <div className={componentStyles.item1}>
              <img
                src="/icons/tick.svg"
                alt="tick image"
                width={breakpoints.mediaBefore800 ? '36' : '30'}
                height={breakpoints.mediaBefore800 ? '26' : '30'}
              />
              <div>
                {intl.formatMessage({
                  id: "projects.convidi.results.paragraph-3",
                })}
              </div>
            </div>
          </div>
          <img
            className={componentStyles.item2}
            src={Phones}
            alt="Illustration with phones"
            width={breakpoints.mediaBefore800 ? '272' : '585'}
            height={breakpoints.mediaBefore800 ? '254' : '546'}
          />
        </div>
      </section>
      <LearnMoreSection/>
    </Layout>
  )
}
export default LoyaltyManager
