import React, { useState } from "react"
import componentStyles from "./Modal.module.scss"
import CloseIcon from "../../assets/images/icons/close-icon.svg"

interface Modal {
  handleClose: any,
  show: boolean,
  children: any
}

const Modal: React.FC<Modal> = ({ handleClose, show, children }) => {
  const showHideClassName = show ? componentStyles.modal + ' ' + componentStyles.open: componentStyles.modal;

  return (
    <div className={showHideClassName}>
      <div className={ componentStyles.modalMain}>
        <img
          className={componentStyles.closeIcon}
          src={CloseIcon}
          alt="Close icon"
          width="18"
          height="18"
          onClick={() => handleClose()}
        />
        {children}
      </div>
    </div>
  );
};

export default Modal
