import React, { useState } from "react"
import componentStyles from "./LearnMoreSection.module.scss"
import Button from "../Button/Button"
import { useIntl } from "gatsby-plugin-intl"
import Modal from "../Modal/Modal"
import Input from "../Input/Input"
import usePresentationForm from "../../hooks/usePresentationForm"

interface LearnMoreSection {}

const LearnMoreSection: React.FC<LearnMoreSection> = () => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)

  const submitTextBtn = intl.formatMessage({ id: "index.banner.submit" })
  const learnMoreText = intl.formatMessage({ id: "projects.learn-more-section.learn-more-text" })
  const thankYouText = intl.formatMessage({ id: "projects.learn-more-section.thank-you-text" })
  const promiseText = intl.formatMessage({ id: "projects.learn-more-section.promise-text" })
  const learnMoreSectionText = intl.formatMessage({ id: "projects.learn-more-section.text" })
  const modalText = intl.formatMessage({ id: "projects.learn-more-section.modal-text" })
  const okTextBtn = 'Ok'
  const cancelTextBtn = 'Cancel'

  const {
    emailValue,
    errorMsg,
    isSubmitting,
    onEmailChange,
    handleSubmit,
    onCloseForm,
    dirty
  } = usePresentationForm()

  const onClose = () => {
    setIsOpen(false);
    onCloseForm();
  }

  return (
    <>
      <div className={componentStyles.container}>
        <div className={componentStyles.describeText + ' ' + componentStyles.presentationFormText}>
          {learnMoreSectionText}
        </div>
        <img
          className={componentStyles.cubeMedium}
          src="/images/cube-medium.png"
          alt="Medium size orange graphic cube image"
          width="57"
          height="75"
        />
        <img
          className={componentStyles.cubeSmall}
          src="/images/cube-medium.png"
          alt="Orange graphic cube image"
          width="18"
          height="25"
        />
        <Button
          className={componentStyles.presentationFormButton}
          id="updateDetails"
          color="orange"
          filled={true}
          type="button"
          onClick={() => setIsOpen(true)}
        >
          {learnMoreText}
        </Button>
      </div>
      <Modal show={isOpen} handleClose={onClose}>
          {isSubmitting &&
            <div>
              <div className={componentStyles.describeText}>{thankYouText}</div>
              <div className={componentStyles.successfulMsg}>{promiseText}</div>
              <Button
                  color="orange"
                  filled={true}
                  className={componentStyles.okBtn}
                  onClick={onClose}
                >
                  {okTextBtn}
                </Button>
            </div>}
          {!isSubmitting &&
            <form onSubmit={handleSubmit}>
              <div className={componentStyles.describeText + ' ' + componentStyles.modalText }>
                {modalText}
              </div>
              <div>
                <Input
                  name={"email"}
                  errorMsg={errorMsg}
                  placeholder={intl.formatMessage({ id: "index.banner.placeholder" })}
                  value={emailValue}
                  className={componentStyles.inputEmail}
                  onChange={onEmailChange}/>
              </div>
              <div className={componentStyles.rightBlock}>
                <button value="cancel" className={componentStyles.cancel} onClick={onClose}>
                  {cancelTextBtn}
                </button>
                <Button
                  color="orange"
                  filled={true}
                  type="submit"
                  disabled={!dirty}
                >
                  {submitTextBtn}
                </Button>
              </div>
            </form>
          }
      </Modal>
    </>
  )
}


export default LearnMoreSection
